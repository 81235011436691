import React from 'react'
import LogoMails from './LogoMails.png'
import '../NavBar/NavBar.css';


function Logo() {
  return (
    <img className="logo" src={LogoMails} alt="" />
  )
}

export default Logo